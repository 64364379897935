
import { MapConfig } from 'lib-customizations/types'

const mapConfig: MapConfig = {
  center: [61.33864, 22.90414],
  initialZoom: 13,
  addressZoom: 14,
  searchAreaRect: {
    maxLatitude: 61.6097021115188,
    minLatitude: 61.19848873922288,
    maxLongitude: 23.273479607294263,
    minLongitude: 22.403159107936858,
  },
  careTypeFilters: ['DAYCARE', 'PRESCHOOL', 'CLUB'],
  unitProviderTypeFilters: [
    'MUNICIPAL',
    'PURCHASED',
    'PRIVATE',
    'PRIVATE_SERVICE_VOUCHER',
  ],
}

export default mapConfig
