
export type Env = 'staging' | 'prod'

export const env = (): Env | 'default' => {
  if (window.location.host.endsWith('evakakehitys.fi')) {
    return 'staging'
  }

  if (window.location.host.endsWith('.fi')) {
    return 'prod'
  }

  return 'default'
}
